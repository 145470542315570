<template>
<main class="flex flex-col h-full relative">
	<NavbarPrimary class="z-50" />
	<div class="flex flex-col grow justify-center items-center p-5">
		<div class="rounded-2xl shadow-xl shadow-neutral-200/40 bg-white w-full h-full lg:w-5/6 lg:h-5/6 flex flex-col z-40">
			<div class="h-3 rounded-2xl"></div>
			<div class="bg-white p-5">
				<form class="flex w-full items-center" @submit.prevent="loadUrl">
					<div class="bg-neutral-100 h-10 rounded-lg grow mr-5"></div>
					<div class="flex items-center justify-center cursor-pointer" @click.prevent="loadChromaDemo">
						<svg class="w-6 block" viewBox="0 0 201 201" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0.000719337 0.000717553V99.7519H100C100 73.2957 89.4643 47.924 70.7102 29.2171C51.9575 10.5095 26.5212 0 0 0L0.000719337 0.000717553Z" fill="#FE5489" />
							<path d="M100.001 0.000717553V99.7519H200C200 73.2957 189.464 47.924 170.71 29.2171C151.957 10.5095 126.521 0 100 0L100.001 0.000717553Z" fill="#FE5489" />
							<path d="M100.499 150.001C100.499 122.249 78.0017 99.7518 50.2496 99.7518C22.4975 99.7518 0 122.249 0 150.001C0 177.754 22.4975 200.251 50.2496 200.251C78.0017 200.251 100.499 177.754 100.499 150.001Z" fill="#FE5489" />
							<path d="M100.501 99.7527V199.504H200.5C200.5 173.048 189.964 147.676 171.21 128.969C152.457 110.261 127.021 99.752 100.5 99.752L100.501 99.7527Z" fill="#FE5489" />
						</svg>
					</div>
				</form>
			</div>
			<section class="flex justify-between grow flex-col relative p-12 md:p-12">
				<div class="">
					<div id="tooltip-helper" class="invisible md:visible">
						<div class="absolute text-sm font-semibold top-7 right-20">
							Right here, mate!
						</div>
						<img class="absolute top-0 right-7" src="@/assets/images/tooltip-icon.svg">
					</div>
					<div class="text-5xl m-0 font-black tracking-tight">
						Give it a test drive.
					</div>
					<div class="max-w-xl text-lg my-4">
						Launch the demo app by clicking the Chroma icon in the toolbar. Sample colors from the swatch below, or anywhere on your screen!
					</div>
					<div class="text-xs max-w-lg leading-relaxed opacity-50">
						Note: Chroma uses experimental Web APIs for its core functionality. We support version 95 and up on Chrome and Edge. Some features will not work in the demo environment.
					</div>
				</div>
				<div class="flex items-center justify-center grow my-10 cursor-pointer">
					<div class="w-20 h-20 md:w-32 md:h-32 rounded-full bg-rose-400 ml-20 md:ml-32"></div>
					<div class="w-20 h-20 md:w-32 md:h-32 rounded-full bg-fuchsia-400 -translate-x-1/4 hover:z-50"></div>
					<div class="w-20 h-20 md:w-32 md:h-32 rounded-full bg-violet-400 -translate-x-1/2 hover:z-50"></div>
					<div class="w-20 h-20 md:w-32 md:h-32 rounded-full bg-blue-400 -translate-x-3/4 hover:z-50"></div>
					<div class="w-20 h-20 md:w-32 md:h-32 rounded-full bg-cyan-400 -translate-x-full hover:z-50"></div>
				</div>
				<div class="flex items-center">
					<div class="mr-4 text-sm uppercase font-semibold tracking-wider">
						Add to your browser
					</div>
					<div class="flex items-center">
						<a href="https://chrome.google.com/webstore/detail/chroma/pkgejkfioihnchalojepdkefnpejomgn" target="_blank" class="flex w-12 h-12 flex items-center justify-center rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
							<img class="w-8 h-8" src="@/assets/images/browser-icons/chrome.svg">
						</a>
						<a href="https://microsoftedge.microsoft.com/addons/detail/chroma/kpibgeoppdepoloehjkdagmhmfejgkgb" target="_blank" class="mx-2 flex w-12 h-12 flex items-center justify-center rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
							<img class="w-8 h-8" src="@/assets/images/browser-icons/edge.svg">
						</a>
						<a href="https://chrome.google.com/webstore/detail/chroma/pkgejkfioihnchalojepdkefnpejomgn" target="_blank" class="flex w-12 h-12 flex items-center justify-center rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
							<img class="w-8 h-8" src="@/assets/images/browser-icons/brave.svg">
						</a>
					</div>
				</div>
			</section>
		</div>
	</div>
</main>
</template>

<script>
import '@/assets/fonts/shim.css';
import '@/assets/js/chroma.vendors.js';
import { gsap } from "gsap";
import NavbarPrimary from '@/components/Navbar';
export default {
	name: 'TryView',
	components: {
		NavbarPrimary
	},
	data () {
		return {
			browse: false,
			url: null
		}
	},
	methods: {
		loadUrl () {
			this.browse = this.url;
		},
		isChromaDemoLoaded () {
			return document.getElementsByTagName('chroma-container').length > 0;
		},
		loadChromaDemo () {
			if (this.isChromaDemoLoaded()) {
				return;
			}
			
			let tag = document.createElement('chroma-container');
			tag.setAttribute('num-colors', 5);
			tag.setAttribute('style', 'pointer-events: none;');
			document.body.appendChild(tag);

			const element = document.getElementById("tooltip-helper");
			element.classList.add("hidden");
		}
	},
	async mounted() {
		gsap.to(".opt-in", {
			opacity: 1,
			visibility: 'visible',
			delay: 0.25,
			duration: 0.25
		});
	}
}
</script>

<style lang="scss" scoped>

main {
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #21D4FD;
		background-image: linear-gradient(19deg, white 0%, #B721FF 70%);
		background-size: 100%;
		background-position: top;
		background-repeat: no-repeat;
		opacity: .1;
	}
}

#tooltip-helper {
	&.hidden {
		opacity: 0;
		visiblity: hidden;
	}
}
</style>
