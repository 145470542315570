<template>
	<main class="flex flex-col z-40 relative min-h-full">
		<NavbarPrimary class="z-50" />
		<router-view />
	</main>
	<PrimaryFooter />
</template>

<script>
import NavbarPrimary from '@/components/Navbar';
import PrimaryFooter from '@/components/PrimaryFooter';
export default {
	name: 'ColorView',
	components: {
		NavbarPrimary,
		PrimaryFooter
	}
}
</script>

<style lang="scss" scoped>
</style>
