<template>
<router-view class="overflow-x-hidden" />
</template>

<script>
export default {
	name: 'App'
}
</script>

<style lang="scss">
@import "@aminoeditor/vue-notify";
@import "assets/css/app.scss";
</style>

<style lang="scss">

</style>
