<template>
<NavbarPrimary />
<header>
	<div class="container">
		<h1 class="h1">FAQs</h1>
		<div class="text-muted">
			Learn more about Chroma and our roadmap.
		</div>
	</div>
</header>
<section class="body">
	<div class="container">

	</div>
</section>
<FooterPrimary />
</template>

<script>
import NavbarPrimary from '@/components/Navbar';
import FooterPrimary from '@/components/PrimaryFooter';
export default {
	name: 'SupportFAQs',
	components: {
		NavbarPrimary,
		FooterPrimary
	}
}
</script>

<style lang="scss" scoped>
.container {
	max-width: 700px;
	margin: auto;
}
header {
	background-color: var(--color-background);
	padding: 3rem 1.5rem;
}
.body {
	padding: 3rem 1.5rem;
}
.h1 {
	font-size: 3rem;
	color: var(--color-primary);
	margin-bottom: 0.5rem;
}
.h2,
.h3,
p {
	margin: 1.5rem 0;
}
ul {
	list-style: none;
}
a {
	color: var(--color-text);
}
</style>
