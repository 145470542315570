<template>
	<draggable v-model="colors" item-key="key" @change="updateRouteKey" class="flex flex-col md:flex-row grow" handle=".drag-handle">
		<template #item="{element}">
			<ColorColumn :allowAdd="colors.length < 5" @addColor="addColor(element)" :color="element.color.replace('#', '')" :movable="colors.length > 1" :removable="colors.length > 1" @remove="removeColor(element.key)"><input class="color-picker absolute top-0 left-0 w-full h-full" type="color" :value="element.color" @change="(e) => { element.color = e.target.value; updateRouteKey() }" /></ColorColumn>
		</template>
	</draggable>
	<div class="add flex w-14 xl:w-20 items-center justify-center absolute top-16 right-0 bottom-0 group/add">
		<div v-if="colors.length < 5" @click="addColor(colors[colors.length-1], true)" class="tooltip flex justify-center items-center cursor-pointer w-8 h-8 text-sm rounded-full text-black bg-white shadow-sm transition-all duration-300 delay-100 opacity-0 invisible scale-90 group-hover/add:opacity-100 group-hover/add:visible group-hover/add:scale-100 hover:scale-105">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
				<path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
				</svg>
			<span class="tooltiptext">
				Add a color
			</span>
		</div>
	</div>
	<div style="top: calc(68px + 1rem)" class="hidden md:block absolute top-4 right-4 max-w-sm z-40">
		<Ad type="carbon" v-slot="{ ad, renderLink, renderImage }" show :key="paletteKey">
			<a class="bg-white rounded-lg block p-2" :href="renderLink(ad.statlink)" target="_self" v-if="ad.rendering == 'custom'">
				<div class="flex items-center">
					<div class="rounded mr-3 block p-2" :style="{ 'background-color': ad.backgroundColor}">
						<img :src="renderImage(ad.image)" class="w-16 block" />
					</div>
					<div class="w-full flex flex-col">
						<div class="text-xs">
							{{ ad.description }}
						</div>
						<div class="text-xs opacity-50 mt-1">
							Sponsored by Carbon
						</div>
					</div>
				</div>
			</a>
			<a class="bg-white rounded-lg block p-2" :href="renderLink(ad.statlink)" target="_self" v-if="ad.rendering == 'carbon'">
				<div class="flex items-center">
					<div class="mr-3">
						<img :src="renderImage(ad.smallImage)" class="w-20 block rounded-sm" />
					</div>
					<div class="w-full flex flex-col">
						<div class="text-xs">
							{{ ad.description }}
						</div>
						<div class="text-xs opacity-50 mt-1">
							Sponsored by Carbon
						</div>
					</div>
				</div>
			</a>
		</Ad>
	</div>
</template>

<script>
import { chroma } from '@/util/ColorUtil';
import draggable from 'vuedraggable';
import Ad from '@/components/Ad';
import ColorColumn from '@/components/ColorColumn';
export default {
	name: 'ColorPaletteView',
	components: {
		draggable,
		Ad,
		ColorColumn
	},
	data() {
		return {
			colors: [],
			paletteKey: Date.now(),
			paletteChanges: 0
		}
	},
	computed: {
		colorKey() {
			return this.$route.params.key;
		}
	},
	methods: {
		updateRouteKey () {
			const key = Array.from(this.colors)
			.map(c => c.color.replace('#', ''))
			.join('-').toUpperCase();
			this.$router.replace({
				name: 'Color Palette',
				params: {
					key
				}
			});
		},
		async addColor({ key, color }, last=false) {
			let startColor, endColor;
			if (key == 0) {
				startColor = '#ffffff';
				endColor = color;
			} else if (last) {
				startColor = color;
				endColor = '#000000';
			} else {
				startColor = this.colors[key - 1].color;
				endColor = color;
			}
			const newColor = chroma.scale([startColor, endColor]).colors(3)[1];
			if (last) {
				this.colors.push({ color: newColor });
			} else {
				this.colors.splice(key, 0, { color: newColor });
			}
			this.updateRouteKey();
		},
		removeColor(key) {
			this.colors.splice(this.colors.findIndex(c => c.key == key), 1);
			this.updateRouteKey();
		},
		mapColors(key) {
			this.colors = [];
			const colorArr = key ? key.split('-') : this.colorKey.split('-');
			for (let i = 0; i < colorArr.length; i++) {
				this.colors.push({
					key: i,
					color: `#${colorArr[i]}`
				});
			}
		},
	},
	watch: {
		colors: function () {
			this.paletteChanges += 1;
			if (this.paletteChanges >= 5) {
				this.paletteKey = Date.now();
				this.paletteChanges = 0;
			}
		}
	},
	mounted() {
		this.mapColors();
	},
	beforeRouteUpdate (to, from) {
		if (to.params.key != from.params.key) {
			this.mapColors(to.params.key);
		}
	}
}
</script>
<style>
body {
	margin: 0px !important;
	padding: 0px !important;
}
</style>
<style scoped lang="scss">
.columns {
	@media only screen and (max-width: 700px) {
		flex-direction: column;
	}
}

.color-picker {
	position: absolute;
	top: 0;

	opacity: 0;
	cursor: pointer;
}

</style>
