<template>
<div class="d-flex flex-column h-100">
	<NavbarPrimary />
	<div class="d-flex flex-column justify-content-center align-items-center text-center flex-grow-1">
		<div class="h1">
			404
		</div>
		<div class="message">
			We were unable to find this page:
			<div class="text-muted">
				/{{ path }}
			</div>
		</div>
	</div>
	<FooterPrimary />
</div>
</template>

<script>
import NavbarPrimary from '@/components/Navbar';
import FooterPrimary from '@/components/PrimaryFooter';
export default {
	name: 'NotFound',
	components: {
		NavbarPrimary,
		FooterPrimary
	},
	computed: {
		path() {
			return this.$route.params.pathMatch;
		}
	}
}
</script>
