<template>
	<div v-if="ad">
		<slot :ad="ad" :renderLink="renderLink" :renderImage="renderImage" />
	</div>
</template>

<script>
import axios from 'axios';
export default {
	name: 'AdMain',
	props: {
		'type': String,
		'show': Boolean
	},
	data() {
		return {
			apiURL: 'https://api.chroma.dev',
			ad: false
		};
	},
	methods: {
		renderLink (url) {
			return `${this.apiURL}/cdn/r/${window.btoa(url)}`
		},
		renderImage (url) {
			return `${this.apiURL}/cdn/img/${window.btoa(url)}`;
		}
	},
	async mounted() {
		if (this.type === 'carbon') {
			this.ad =
				await axios.get(`${this.apiURL}/ads/carbon?placement=chroma`).then(ad => {
					this.$gtag.event('ad_render', {
						'event_category': 'carbon_ads',
						'event_label': `Rendered Ad From ${this.ad.company}`,
						'non_interaction': true
					});
					return ad.data;
				}).catch(e => {
					console.log(e);
					return false;
				});
		}
	}
}
</script>

<style lang="scss">
.carbon {
	width: 100%;
	max-width: 240px;
	border-color: var(--color-700) !important;
	padding: 1rem;
	font-weight: var(--font-weight-normal);
	font-size: 12px;
	line-height: 1.4;
	.btn {
		height: auto !important;
		line-height: 1.3 !important;
		padding: 0.5rem 0.75rem;
		font-size: 12px;
	}
	&:hover {
		text-decoration: none;
	}
}
</style>
