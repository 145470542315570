<template>
<nav class="w-full font-semibold px-5 py-3">
	<div class="flex justify-between items-center">
		<div class="flex items-center">
			<div class="mr-6">
				<IconMain />
			</div>
			<a href="javascript:void(0);" class="flex items-center text-sm h-11 font-semibold py-2 px-4 bg-white border border-neutral-300 rounded-full hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100" @click.prevent="generateRandomPalette">
				Create a palette
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 ml-2">
					<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
				</svg>
			</a>
		</div>
		<div class="flex items-center">
			<div class="form">
				<SearchForm />
			</div>
			<div class="hidden md:block relative text-sm mx-6">
				<router-link to="/try">
					Try Chroma
				</router-link>
			</div>
			<div class="relative">
				<ToolsMenu />
			</div>
		</div>
	</div>
</nav>
</template>

<script>
import IconMain from '@/components/Icon';
import ToolsMenu from '@/components/ToolsMenu';
import SearchForm from '@/components/SearchForm';
import { ColorHelpers } from '@/util/ColorUtil';
export default {
	name: 'NavbarPrimary',
	components: {
		IconMain,
		SearchForm,
		ToolsMenu
	},
	methods: {
		generateRandomPalette () {
			const palette = ColorHelpers.getRandomPalette();
			const key = palette.map(c => c.replace('#', '').toUpperCase()).join('-');
			this.$router.push(`/${key}`);
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
