<template>
	<main class="d-flex flex-column bg-white relative z-50 shadow-xl shadow-neutral-200/40">
		<NavbarPrimary class="z-50" />
		<div class="max-w-7xl my-24 mx-auto bg-white z-50">
		<div class="grid gap-px border bg-gray-150 lg:min-h-[540px] lg:grid-cols-12">
			<div class="flex lg:col-span-6 xl:col-span-7 2xl:col-span-8">
				<div class="flex grow items-center justify-center bg-white p-16">
					<IconMain class="yuge" />
				</div>
			</div>
			<div class="flex flex-col divide-y divide-gray-150 bg-white lg:col-span-6 xl:col-span-5 2xl:col-span-4">
				<div class="space-y-4 p-8 md:p-12">
					<p>Chroma is a color tool for sampling colors on the web, building palettes, and </p>
					<p>We built Chroma to speed up our own workflow, and to help others with theirs too.</p>
				</div>
				<div class="space-y-4 p-8 md:p-12">
					<div class="text-xs font-semibold uppercase text-gray-300">
						Made by
					</div>
					<div class="space-y-8">
						<div class="flex">
							<div class="pr-4 sm:pl-4 sm:pr-0">
								<a href="https://github.com/ericuldall" class="inline-block text-lg font-semibold">
									Timothy Long
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 10" role="img" class="ml-1 mt-0.5 inline-block h-2.5 w-2.5 align-baseline text-gray-300">
										<path fill="currentColor" d="M10 .5V7a.7.7 0 0 1-1.4.1v-4h-.1L2.2 9.2c-.2.3-.6.3-1 0a.7.7 0 0 1-.1-1L7.6 2l-.1-.1h-4a.7.7 0 0 1-.7-.6V1c0-.3.2-.6.5-.7H10Z"></path>
									</svg>
								</a>
								<p class="text-sm leading-[1.6] text-gray-500">
									Designer, author and creator of numerous tools for designers.
								</p>
							</div>
							<img alt="Artiom Dashinsky" src="https://avatars.githubusercontent.com/u/1380218?v=4" width="128" height="128" decoding="async" data-nimg="future" class="h-10 w-10 object-contain sm:order-first sm:h-16 sm:w-16" loading="lazy" style="color:transparent">
						</div>
						<div class="flex">
							<div class="pr-4 sm:pl-4 sm:pr-0">
								<a href="https://wesse.ly" class="inline-block text-lg font-semibold">
									Martin Wessely
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 10" role="img" class="ml-1 mt-0.5 inline-block h-2.5 w-2.5 align-baseline text-gray-300">
										<path fill="currentColor" d="M10 .5V7a.7.7 0 0 1-1.4.1v-4h-.1L2.2 9.2c-.2.3-.6.3-1 0a.7.7 0 0 1-.1-1L7.6 2l-.1-.1h-4a.7.7 0 0 1-.7-.6V1c0-.3.2-.6.5-.7H10Z"></path>
									</svg>
								</a>
								<p class="text-sm leading-[1.6] text-gray-500">
									Developer and designer. Previously lead developer at
									<a href="https://hellogustav.com" class="underline" target="_blank" rel="noreferrer">
										Gustav
									</a>, co-founder of<!-- --> <a href="https://getbedtimestories.com" class="underline" target="_blank" rel="noreferrer">Bedtime Stories</a> <!-- -->and<!-- --> <a href="https://besucherliste.at" class="underline" target="_blank" rel="noreferrer">Besucherliste</a>.
								</p>
							</div>
							<img alt="Martin Wessely" srcset="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fmartin%402x.b7d1fa8c.png&amp;w=128&amp;q=75 1x, /_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fmartin%402x.b7d1fa8c.png&amp;w=256&amp;q=75 2x" src="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fmartin%402x.b7d1fa8c.png&amp;w=256&amp;q=75" width="128" height="128" decoding="async" data-nimg="future" class="h-10 w-10 object-contain sm:order-first sm:h-16 sm:w-16" loading="lazy" style="color:transparent"></div></div></div><div class="mt-auto space-y-4 px-8 py-6 md:px-12"><a href="mailto:hvoostik@gmail.com" class="inline-flex items-center text-lg font-semibold">Email us<!-- --> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 10" role="img" class="ml-2 mt-0.5 h-2.5 w-2.5 text-gray-300"><path fill="currentColor" d="M10 .5V7a.7.7 0 0 1-1.4.1v-4h-.1L2.2 9.2c-.2.3-.6.3-1 0a.7.7 0 0 1-.1-1L7.6 2l-.1-.1h-4a.7.7 0 0 1-.7-.6V1c0-.3.2-.6.5-.7H10Z"></path></svg></a></div></div></div>
						</div>
	</main>
	<PrimaryFooter />
	</template>
	
	<script>
	import NavbarPrimary from '@/components/Navbar';
	import PrimaryFooter from '@/components/PrimaryFooter';
	import IconMain from '@/components/Icon';
	export default {
		name: 'AboutView',
		components: {
			NavbarPrimary,
			PrimaryFooter,
			IconMain
		},
		async mounted() {

		}
	}
	</script>
	
	<style lang="scss" scoped>
	main {
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #21D4FD;
		background-image: linear-gradient(19deg, white 0%, #B721FF 70%);
		background-size: 100%;
		background-position: top;
		background-repeat: no-repeat;
		opacity: .1;
	}
}
	</style>
	