import {
	createWebHistory,
	createRouter
} from "vue-router";
import {
	seoGuard
} from '@aminoeditor/vue-router-seo';
import { ColorHelpers } from '@/util/ColorUtil';
import IndexView from "@/views/Index.vue";
import ColorView from "@/views/Color.vue";
import ColorSingleView from "@/views/Color/Single.vue";
import ColorPaletteView from "@/views/Color/Palette.vue";
import OnboardingView from "@/views/Onboarding.vue";
import TintsShadesView from "@/views/TintsShades.vue";
import TryView from "@/views/Try.vue";
import AboutView from "@/views/About.vue";
import GradientPalettesView from "@/views/GradientPalettes.vue";
import SupportFAQs from "@/views/support/FAQs.vue";
import SupportPrivacy from "@/views/support/Privacy.vue";
import SupportTerms from "@/views/support/Terms.vue";
import NotFound from "@/views/errors/NotFound.vue";

const defaultMeta = {
	title: 'Chroma'
};
const routes = [{
		path: "/",
		name: "IndexView",
		component: IndexView,
		meta: {
			seo: {
				...defaultMeta,
				title: `${defaultMeta.title} – Colors for everyone.`,
				metaTags: [
					{
						name: 'description',
						content: 'Pick colors from any website and build amazing and shareable color palettes.'
					},
					{
						property: 'og:title',
						content: defaultMeta.title
					},
					{
						property: 'og:description',
						content: 'Pick colors from any website and build amazing and shareable color palettes.'
					},
					{
						property: 'og:url',
						content: 'https://chroma.dev'
					},
					{
						property: 'og:image',
						content: 'https://chroma.dev/images/share.png'
					},
					{
						property: 'twitter:title',
						content: defaultMeta.title
					},
					{
						name: 'twitter:description',
						content: 'Pick colors from any website and build amazing and shareable color palettes.'
					},
					{
						name: 'twitter:image',
						content: 'https://chroma.dev/images/share.png'
					},
					{
						property: 'twitter:url',
						content: 'https://chroma.dev'
					}
				],
				richSnippet: {
					"@context": "https://schema.org",
					"@type": "Project",
					"name": "Chroma",
					"url": "https://chroma.dev",
					"logo": "https://chroma.dev/images/share.png"
				}
			}
		}
	},
	{
		path: "/gradient-palettes/:startColor?/:endColor?",
		name: "Gradient Palettes",
		component: GradientPalettesView,
		meta: {
			seo: {
				...defaultMeta,
				title: (route) => `Gradient Palette – #${route.params.startColor} To #${route.params.endColor} – ${defaultMeta.title}`,
				metaTags: [
					{
						name: 'description',
						content: 'Generate a palette between two colors.'
					},
					{
						property: 'og:title',
						content: (route) => `Gradient Palette – #${route.params.startColor} To #${route.params.endColor} – ${defaultMeta.title}`,
					},
					{
						property: 'og:description',
						content: 'Generate a palette between two colors.'
					},
					{
						property: 'og:url',
						content: (route) => `https://chroma.dev${route.fullPath}`
					},
					{
						property: 'og:image',
						content: 'https://chroma.dev/images/share.png'
					},
					{
						property: 'twitter:title',
						content: defaultMeta.title
					},
					{
						name: 'twitter:description',
						content: 'Generate a palette between two colors.'
					},
					{
						name: 'twitter:image',
						content: 'https://chroma.dev/images/share.png'
					},
					{
						property: 'twitter:url',
						content: (route) => `https://chroma.dev${route.fullPath}`
					}
				],
				richSnippet: {
					"@context": "https://schema.org",
					"@type": "Project",
					"name": "Chroma",
					"url": "https://chroma.dev",
					"logo": "https://chroma.dev/images/share.png"
				}
			}
		}
	},
	{
		path: "/tints-and-shades/:color?/:mode?",
		name: "Tints & Shades",
		component: TintsShadesView,
		meta: {
			seo: {
				...defaultMeta,
				title: (route) => `${route.params.mode[0].toUpperCase() + route.params.mode.slice(1) || 'Tints'} – #${route.params.color.toUpperCase()} – Tints & Shades – ${defaultMeta.title}`,
				metaTags: [
					{
						name: 'description',
						content: 'Generate tints and shades for any color.'
					},
					{
						property: 'og:title',
						content: (route) => `${route.params.mode[0].toUpperCase() + route.params.mode.slice(1) || 'Tints'} – #${route.params.color.toUpperCase()} – Tints & Shades – ${defaultMeta.title}`,
					},
					{
						property: 'og:description',
						content: 'Generate tints and shades for any color.'
					},
					{
						property: 'og:url',
						content: (route) => `https://chroma.dev${route.fullPath}`
					},
					{
						property: 'og:image',
						content: 'https://chroma.dev/images/share.png'
					},
					{
						property: 'twitter:title',
						content: defaultMeta.title
					},
					{
						name: 'twitter:description',
						content: 'Generate tints and shades for any color.'
					},
					{
						name: 'twitter:image',
						content: 'https://chroma.dev/images/share.png'
					},
					{
						property: 'twitter:url',
						content: (route) => `https://chroma.dev${route.fullPath}`
					}
				],
				richSnippet: {
					"@context": "https://schema.org",
					"@type": "Project",
					"name": "Chroma",
					"url": "https://chroma.dev",
					"logo": "https://chroma.dev/images/share.png"
				}
			}
		}
	},
	{
		path: "/onboarding",
		name: "Onboarding",
		component: OnboardingView,
		meta: {
			seo: {
				...defaultMeta,
				title: `Getting Started – ${defaultMeta.title}`
			}
		}
	},
	{
		path: "/try",
		name: "Try Chroma",
		component: TryView,
		meta: {
			seo: {
				...defaultMeta,
				title: `Try Chroma – ${defaultMeta.title}`,
				metaTags: [
					{
						name: 'description',
						content: 'Try the Chroma extension on our demo page.'
					},
					{
						property: 'og:title',
						content: defaultMeta.title
					},
					{
						property: 'og:description',
						content: 'Try the Chroma extension on our demo page.'
					},
					{
						property: 'og:url',
						content: 'https://chroma.dev/try/'
					},
					{
						property: 'og:image',
						content: 'https://chroma.dev/images/share.png'
					},
					{
						property: 'twitter:title',
						content: defaultMeta.title
					},
					{
						name: 'twitter:description',
						content: 'Try the Chroma extension on our demo page.'
					},
					{
						name: 'twitter:image',
						content: 'https://chroma.dev/images/share.png'
					},
					{
						property: 'twitter:url',
						content: 'https://chroma.dev/try/'
					}
				],
				richSnippet: {
					"@context": "https://schema.org",
					"@type": "Project",
					"name": "Chroma",
					"url": "https://chroma.dev",
					"logo": "https://chroma.dev/images/share.png"
				}
			}
		}
	},
	{
		path: "/about",
		name: "About",
		component: AboutView,
		meta: {
			seo: {
				...defaultMeta,
				title: `FAQs – ${defaultMeta.title}`,
				metaTags: [
					{
						name: 'description',
						content: `Frequently asked questions about ${defaultMeta.title} and our roadmap.`
					}
				]
			}
		}
	},
	{
		path: "/faqs",
		name: "FAQs",
		component: SupportFAQs,
		meta: {
			seo: {
				...defaultMeta,
				title: `FAQs – ${defaultMeta.title}`,
				metaTags: [
					{
						name: 'description',
						content: `Frequently asked questions about ${defaultMeta.title} and our roadmap.`
					}
				]
			}
		}
	},
	{
		path: "/privacy",
		name: "Privacy",
		component: SupportPrivacy,
		meta: {
			seo: {
				...defaultMeta,
				title: `Privacy Policy – ${defaultMeta.title}`,
				metaTags: [
					{
						name: 'description',
						content: `Learn about how we honor user privacy.`
					}
				]
			}
		}
	},
	{
		path: "/terms",
		name: "Terms",
		component: SupportTerms,
		meta: {
			seo: {
				...defaultMeta,
				title: `Terms of Use – ${defaultMeta.title}`,
				metaTags: [
					{
						name: 'description',
						content: `Please read this agreement carefully before accessing or using ${defaultMeta.title}.`
					}
				]
			}
		}
	},
	{
		path: "/",
		name: "Color",
		component: ColorView,
		children: [
			{
				path: "/:color([^G-Zg-z-]*)",
				name: "Color Single",
				component: ColorSingleView,
				meta: {
					expandColorKeys: ['color'],
					seo: {
						...defaultMeta,
						title: route => `Color ${route.params.color.toUpperCase()} – ${defaultMeta.title}`
					}
				}
			},
			{
				path: "/:key/:title?",
				name: "Color Palette",
				component: ColorPaletteView,
				meta: {
					seo: {
						...defaultMeta,
						title: route => `Color Palette: ${route.params.key.split('-').map(c => `#${c}`).join(', ')} – ${defaultMeta.title}`,
						metaTags: [
							{
								name: 'description',
								content:  'Pick colors from any website and build amazing and shareable color palettes.'
							},
							{
								property: 'og:title',
								content: route => `Color Palette: ${route.params.key.split('-').map(c => `#${c}`).join(', ')} – ${defaultMeta.title}`
							},
							{
								property: 'og:description',
								content: 'Pick colors from any website and build amazing and shareable color palettes.'
							},
							{
								property: 'og:url',
								content: route => {
									return `https://chroma.dev${route.fullPath}`;
								}
							},
							{
								property: 'og:image',
								content: route => {
									return `https://us-central1-amino-editor-314718.cloudfunctions.net/palette-og-image?key=${route.params.key}`
								}
							},
							{
								name: 'twitter:card',
								content: 'summary_large_image'
							},
							{
								name: 'twitter:title',
								content: route => `Color Palette: ${route.params.key.split('-').map(c => `#${c}`).join(', ')} – ${defaultMeta.title}`
							},
							{
								name: 'twitter:description',
								content: 'Pick colors from any website and build amazing and shareable color palettes.'
							},
							{
								property: 'twitter:url',
								content: route => {
									return `https://chroma.dev${route.fullPath}`;
								}
							},
							{
								name: 'twitter:image',
								content: route => {
									return `https://us-central1-amino-editor-314718.cloudfunctions.net/palette-og-image?key=${route.params.key}`
								}
							},
						],
						richSnippet: {
							"@context": "https://schema.org",
							"@type": "Project",
							"name": "Chroma",
							"url": "https://chroma.dev",
							"logo": "https://chroma.dev/images/share.png"
						}
					}
				}
			}
		]
	},
	{
		path: "/:pathMatch(.*)*",
		name: "NotFound",
		component: NotFound,
		meta: {
			seo: {
				...defaultMeta,
				title: `Not Found – ${defaultMeta.title}`,
				metaTags: [
					{
						name: 'description',
						content: `We couldn't find the page  you were looking for.`
					}
				]
			}
		}
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {
			left: 0,
			top: 0
		}
	}
});

router.beforeEach((to, from, next) => {
	seoGuard(to, defaultMeta.title)
	if (to.name === "Color Palette") {
		const validKeyRegExpFirstByteFix = /^[0-9a-fA-F]/;
		const validKeyRegExp = /^(((-)?[0-9a-fA-F]{3}|(-)?[0-9a-fA-F]{6})){1,10}$/;
		const {
			key
		} = to.params;
		if (key.match(validKeyRegExpFirstByteFix) && key.match(validKeyRegExp)) {
			const fixedKey = key.split('-').map(ColorHelpers.expandColor).join('-');
			if (key == fixedKey) {
				next();
			} else {
				router.replace({ name: 'Color Palette', params: { key: fixedKey } });
			}
		} else {
			next({ name: 'NotFound', params: { pathMatch: to.fullPath.substring(1) } });
		}
	} else if (to?.meta?.expandColorKeys) {
		const { expandColorKeys } = to.meta;
		const params = {};
		for (let key in to.params) {
			if (expandColorKeys.includes(key)) {
				params[key] = ColorHelpers.expandColor(to.params[key]);
				if (params[key] === to.params[key]) { delete params[key]; }
			}
		}
		if (Object.keys(params).length) {
			router.replace({ name: to.name, params });
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
