<template>
	<main class="flex flex-col z-40 relative bg-white min-h-full" v-if="primaryColor.start && primaryColor.end">
		<NavbarPrimary />
		<header class="flex flex-col items-center justify-center text-center py-10 px-4 h-48">
			<div class="text-5xl font-extrabold leading-none tracking-tight mb-5">
				Gradient Palettes
			</div>
			<div class="flex items-center">
				<div class="mr-4">
					<div class="relative" :class="{error:invalidColor.start}">
						<input class="h-10 pl-3 text-sm font-semibold rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100" type="text" placeholder="HEX value" v-model="primaryColorReplacement.start" @change="updateColorInput('start')">
						<div class="w-6 h-6 absolute top-1/2 -translate-y-1/2 right-2 rounded-full p-1" :style="{backgroundColor: `#${primaryColor.start.replace('#', '')}`}" style="padding: 6px;">
							<input class="w-full top-0 left-0 bottom-0 cursor-pointer h-auto absolute opacity-0" type="color" v-model="primaryColor.start" @change="primaryColorReplacement.start = primaryColor.start" />
						</div>
					</div>
				</div>
				<div class="mr-4">
					<div class="relative" :class="{error:invalidColor.end}">
						<input class="h-10 pl-3 text-sm font-semibold rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100" type="text" placeholder="HEX value" v-model="primaryColorReplacement.end" @change="updateColorInput('end')">
						<div class="w-6 h-6 absolute top-1/2 -translate-y-1/2 right-2 rounded-full p-1" :style="{backgroundColor: `#${primaryColor.end.replace('#', '')}`}" style="padding: 6px;">
							<input class="w-full top-0 left-0 bottom-0 cursor-pointer h-auto absolute opacity-0" type="color" v-model="primaryColor.end" @change="primaryColorReplacement.end = primaryColor.end" />
						</div>
					</div>
				</div>
				<div class="mr-4">
					<div class="relative">
						<input class="flex items-center text-center w-28 h-10 text-sm font-semibold rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100" type="text" v-model="numShades" />
						<div class="absolute top-1/2 px-3 -translate-y-1/2 flex justify-between items-center w-28 h-100">
							<div class="flex items-center justify-center cursor-pointer">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-4 h-4" @click.prevent="adjustNumShades('down')">
									<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
								</svg>
							</div>
							<div class="flex items-center justify-center cursor-pointer">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-4 h-4" @click.prevent="adjustNumShades('up')">
									<path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
								</svg>
							</div>
						</div>
					</div>
				</div>
				<div @click.prevent="randomizeColor">
					<div class="flex justify-center items-center cursor-pointer w-10 h-10 text-sm font-semibold rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.75" stroke="currentColor" class="w-4 h-4">
							<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
						</svg>
					</div>
				</div>
			</div>
		</header>
		<div class="w-full flex grow">
			<div class="palette flex grow relative">
				<div class="item flex grow items-center justify-center text-center basis-0 hover:basis-72 delay-50 transition-all group text-white cursor-pointer" :style="{ backgroundColor: shade }" v-for="(shade) of shades" :key="shade" :class="{ 'is-light': isLight(shade) }" @click.prevent="copyColor(shade.replace('#', ''))">
					<div class="hidden group-hover:block">
						<div class="text-4xl lg:text-5xl font-black tracking-tight uppercase leading-snug">
							{{ shade.replace('#', '') }}
						</div>
					</div>
				</div>
				<div class="hidden md:block absolute bottom-4 right-4 max-w-xs z-50">
					<Ad type="carbon" :cid="['CESDC27Y', 'CESDC277']" v-slot="{ ad, renderLink, renderImage }" show :key="paletteKey">
						<a class="bg-white rounded-lg block rounded-lg p-2" :href="renderLink(ad.statlink)" target="_self" v-if="ad.rendering == 'custom'">
							<div class="flex items-center">
								<div class="rounded mr-3 p-2" :style="{ 'background-color': ad.backgroundColor}">
									<img :src="renderImage(ad.image)" class="w-16 block" />
								</div>
								<div class="w-full flex flex-col">
									<div class="text-xs">
										{{ ad.description }}
									</div>
									<div class="text-xs opacity-50 mt-1">
										Sponsored by Carbon
									</div>
								</div>
							</div>
						</a>
						<a class="bg-white block rounded-lg p-2" :href="renderLink(ad.statlink)" target="_self" v-if="ad.rendering == 'carbon'">
							<div class="flex items-center">
								<div class="mr-3">
									<img :src="renderImage(ad.smallImage)" class="w-20 block rounded-sm" />
								</div>
								<div class="w-full flex flex-col">
									<div class="text-xs">
										{{ ad.description }}
									</div>
									<div class="text-xs opacity-50 mt-1">
										Sponsored by Carbon
									</div>
								</div>
							</div>
						</a>
					</Ad>
				</div>
			</div>
		</div>
		<div class="md:hidden">
			<Ad type="carbon" :cid="['CESDC27Y', 'CESDC277']" v-slot="{ ad, renderLink, renderImage }" show>
				<a class="bg-white rounded-lg block rounded-lg p-4" :href="renderLink(ad.statlink)" target="_blank" v-if="ad.rendering == 'custom'">
					<div class="flex items-center">
						<div class="rounded mr-4 p-2" :style="{ 'background-color': ad.backgroundColor}">
							<img :src="renderImage(ad.image)" class="w-16 block" />
						</div>
						<div class="w-full flex flex-col">
							<div class="text-xs">
								{{ ad.description }}
							</div>
							<div class="text-xs opacity-50 mt-1">
								Sponsored by Carbon
							</div>
						</div>
					</div>
				</a>
				<a class="bg-white block rounded-lg p-4" :href="renderLink(ad.statlink)" target="_blank" v-if="ad.rendering == 'carbon'">
					<div class="flex items-center">
						<div class="mr-4">
							<img :src="renderImage(ad.smallImage)" class="w-20 block rounded-sm" />
						</div>
						<div class="w-full flex flex-col">
							<div class="text-xs">
								{{ ad.description }}
							</div>
							<div class="text-xs opacity-50 mt-1">
								Sponsored by Carbon
							</div>
						</div>
					</div>
				</a>
			</Ad>
		</div>
	</main>
<Footer />
</template>

<script>
import {
	ColorHelpers,
	chroma
} from '@/util/ColorUtil';
import NavbarPrimary from '@/components/Navbar';
import Ad from '@/components/Ad';
import Footer from '@/components/PrimaryFooter';
export default {
	name: 'GradientPalettesView',
	components: {
		NavbarPrimary,
		Ad,
		Footer
	},
	data() {
		return {
			numShades: 6,
			maxShades: 24,
			primaryColor: {
				start: false,
				end: false
			},
			primaryColorReplacement: {
				start: null,
				end: null
			},
			invalidColor: {
				start: false,
				end: false
			},
			paletteKey: Date.now(),
			paletteChanges: 0
		};
	},
	computed: {
		shades() {
			let shades;
			shades = chroma.scale([this.primaryColor.start, this.primaryColor.end]);
			return shades.colors(this.numShades);
		}
	},
	methods: {
		async copyColor(color) {
			await navigator.clipboard.writeText(`${color}`);
			this.$notify(`${color} copied to clipboard`, {
				displayMs: 2500
			});
		},
		adjustNumShades(direction) {
			if (direction == 'up' && this.numShades < this.maxShades) {
				this.numShades++;
			}
			if (direction == 'down' && this.numShades > 1) {
				this.numShades--;
			}
		},
		updateColorInput(key) {
			const validHex = /([0-9a-fA-F]{3}|[0-9a-fA-F]{6})/;
			if (this.primaryColorReplacement[key] && this.primaryColorReplacement?. [key]?.match(validHex)) {
				this.primaryColor[key] = this.primaryColorReplacement[key];
				this.invalidColor[key] = false;
			} else {
				this.invalidColor[key] = true;
			}
		},
		isLight(color) {
			return ColorHelpers.isLight(color);
		},
		randomizeColor() {
			this.primaryColor.start = this.primaryColorReplacement.start = chroma.random().toString();
			this.primaryColor.end = this.primaryColorReplacement.end = chroma.random().toString();
		}
	},
	watch: {
		primaryColorReplacement: {
			handler() {
				this.paletteChanges += 1;
				if (this.paletteChanges >= 5) {
					this.paletteKey = Date.now();
					this.paletteChanges = 0;
				}
				this.$router.replace({
					params: {
						startColor: this.primaryColor?.start?.replace?.('#', '').toUpperCase?.(),
						endColor: this.primaryColor?.end?.replace?.('#', '').toUpperCase?.()
					}
				});
			},
			deep: true
		},
	},
	mounted() {
		this.primaryColor.start = this.primaryColorReplacement.start = this.$route.params.startColor ? `#${this.$route.params.startColor}` : chroma.random().toString();
		this.primaryColor.end = this.primaryColorReplacement.end = this.$route.params.endColor ? `#${this.$route.params.endColor}` : chroma.random().toString();
		//console.log(this.primaryColor);
	}
}
</script>

<style lang="scss" scoped>
.item {
	&.is-light {
		@apply text-black;
	}
}
</style>
