<template>
<div class="flex flex-col z-40 relative bg-white">
	<div class="pt-10 pb-20 px-5 grow">
		<div class="max-w-5xl m-auto">
			<header :style="{ background: `#${color}`, color: isLight ? '#000' : '#fff'  }" class="mb-8 py-48 d-flex flex-column justify-content-center position-relative rounded-2xl border border-neutral-200 shadow-lg shadow-neutral-200/40">
				<h1 class="text-6xl font-black tracking-tight text-center mb-2">
					{{ colorName }}
				</h1>
				<div class="hidden md:block absolute bottom-6 left-6 max-w-md z-50">
					<Ad type="carbon" v-slot="{ ad, renderLink, renderImage }" show :key="color">
						<a class="block" :href="renderLink(ad.statlink)" target="_self" v-if="ad.rendering == 'custom'">
							<div class="flex items-center">
								<div class="rounded mr-4 p-2" :style="{ 'background-color': ad.backgroundColor}">
									<img :src="renderImage(ad.logo)" class="w-52 block" />
								</div>
								<div class="w-full flex flex-col">
									<div class="text-xs">
										{{ ad.description }}
									</div>
									<div class="text-xs opacity-50 mt-1">
										Sponsored by Carbon
									</div>
								</div>
							</div>
						</a>
						<a class="block" :href="renderLink(ad.statlink)" target="_self" v-if="ad.rendering == 'carbon'">
							<div class="flex items-center">
								<div class="mr-4">
									<img :src="renderImage(ad.smallImage)" class="w-20 block rounded-sm" />
								</div>
								<div class="w-full flex flex-col">
									<div class="text-xs">
										{{ ad.description }}
									</div>
									<div class="text-xs opacity-50 mt-1">
										Sponsored by Carbon
									</div>
								</div>
							</div>
						</a>
					</Ad>
				</div>
			</header>
			<section>
				<div class="grid gap-5 grid-cols-1 md:grid-cols-3">
					<div @click.prevent="copy(renderColor(color, 'hex'))" class="group/btn flex items-center justify-between bg-white p-5 rounded-xl cursor-pointer border border-neutral-100 shadow-lg shadow-neutral-200/40 hover:shadow-xl hover:shadow-neutral-200/80">
						<div class="prose">
							<h3 class="m-0">
								HEX
							</h3>
							<div class="text-sm opacity-50">
								{{ renderColor(color, 'hex') }}
							</div>
						</div>
						<div>
							<div class="flex w-10 h-10 flex items-center justify-center rounded-full border border-gray-300 bg-white shadow-sm group-hover/btn:bg-gray-50">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.75" stroke="currentColor" class="w-4 h-4">
									<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
								</svg>
							</div>
						</div>
					</div>
					<div @click.prevent="copy(renderColor(color, 'rgb'))" class="group/btn flex items-center justify-between bg-white p-5 rounded-xl cursor-pointer border border-neutral-100 shadow-lg shadow-neutral-200/40 hover:shadow-xl hover:shadow-neutral-200/80">
						<div class="prose">
							<h3 class="m-0">
								RGB
							</h3>
							<div class="text-sm opacity-50">
								{{ renderColor(color, 'rgb') }}
							</div>
						</div>
						<div>
							<div class="flex w-10 h-10 flex items-center justify-center rounded-full border border-gray-300 bg-white shadow-sm group-hover/btn:bg-gray-50">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.75" stroke="currentColor" class="w-4 h-4">
									<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
								</svg>
							</div>
						</div>
					</div>
					<div @click.prevent="copy(renderColor(color, 'hsl'))" class="group/btn flex items-center justify-between bg-white p-5 rounded-xl cursor-pointer border border-neutral-100 shadow-lg shadow-neutral-200/40 hover:shadow-xl hover:shadow-neutral-200/80">
						<div class="prose">
							<h3 class="m-0">
								HSL
							</h3>
							<div class="text-sm opacity-50">
								{{ renderColor(color, 'hsl') }}
							</div>
						</div>
						<div>
							<div class="flex w-10 h-10 flex items-center justify-center rounded-full border border-gray-300 bg-white shadow-sm group-hover/btn:bg-gray-50">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.75" stroke="currentColor" class="w-4 h-4">
									<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
								</svg>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="mt-24">
				<div class="item mb-24">
					<header class="flex items-center mb-3">
						<div class="text-2xl font-semibold">
							Tints
						</div>
						<div class="ml-3 flex w-10 h-10 flex items-center justify-center rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 cursor-pointer" @click.prevent="$router.push(`/tints-and-shades/${color}/tints`)">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
								<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
							</svg>
						</div>
					</header>
					<div class="swatch flex items-center border border-neutral-200 shadow-lg shadow-neutral-200/40 rounded-xl">
						<div v-for="shade in tints" class="color grow h-32 relative cursor-pointer tooltip" :style="{'background-color': shade}" :key="shade" @click.prevent="copy(renderColor(shade, 'hex'))">
							<div class="tooltiptext flex flex-col">
								<div>
									{{ shade }}
								</div>
								<div>
									Copy to clipboard
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="item">
					<header class="flex items-center mb-3">
						<div class="text-2xl font-semibold">
							Shades
						</div>
						<div class="ml-3 flex w-10 h-10 flex items-center justify-center rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 cursor-pointer" @click.prevent="$router.push(`/tints-and-shades/${color}/shades`)">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
								<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
							</svg>
						</div>
					</header>
					<div class="swatch flex items-center border border-neutral-200 shadow-lg shadow-neutral-200/40 rounded-xl">
						<div v-for="shade in shades" class="color grow h-32 relative cursor-pointer tooltip" :style="{'background-color': shade}" :key="shade" @click.prevent="copy(renderColor(shade, 'hex'))">
							<div class="tooltiptext flex flex-col">
								<div>
									{{ shade }}
								</div>
								<div>
									Copy to clipboard
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</div>
</template>

<script>
import Ad from '@/components/Ad';
import {
	ColorHelpers,
	chroma
} from '@/util/ColorUtil';
export default {
	name: 'ColorSingleView',
	components: {
		Ad
	},
	computed: {
		color() {
			return this.$route.params.color.toUpperCase();
		},
		colorName() {
			return ColorHelpers.getColorName(this.color);
		},
		isLight() {
			return ColorHelpers.isLight(this.color);
		},
		shades() {
			return chroma.scale([this.color, '#000000']).colors(6);
		},
		tints() {
			return chroma.scale(['#ffffff', this.color]).colors(6).reverse();
		}
	},
	methods: {
		renderColor(...args) {
			return ColorHelpers.renderColor(...args);
		},
		async copy(value) {
			await navigator.clipboard.writeText(`${value}`);
			this.$notify(`${value} copied to clipboard`, {
				displayMs: 2500
			});
		}
	}
}
</script>

<style scoped lang="scss">

.hero {
	padding: 12rem 1.5rem;
	margin-bottom: 1.5rem;
	color: white;
	background: #213422;
	border-radius: 1rem;
}

.swatch {
	.color {
		&:first-of-type {
			border-top-left-radius: 0.75rem;
			border-bottom-left-radius: 0.75rem;
		}
		&:last-of-type {
			border-top-right-radius: 0.75rem;
			border-bottom-right-radius: 0.75rem;
		}
	}
}

.fab-container {
	position: absolute;
	bottom: 1rem;
	left: 1rem;
	.fab {
		max-width: 440px;
		margin: 0.5rem auto 0;
		padding: 1rem;
		border-radius: 0.5rem;
		position: relative;
		// background-color: white;
		transition: 0.3s var(--transition-function);
		text-decoration: none;
		// color: var(--color-text);
		color: inherit;
		&.smaller {
			max-width: 240px;
			.logo-wrapper {
				margin-bottom: 1rem;
				img {
					max-width: none;
				}
			}
		}
		&:hover {
			transform: scale(1.025);
		}
		.logo-wrapper {
			margin-right: 1rem;
			padding: 1rem;
			border-radius: 1rem;
			img {
				max-width: 48px;
				// max-width: 2rem;
				// min-width: 1.5rem;
			}
		}
		.description {
			font-size: 0.8rem;
			line-height: 1.3;
		}
		.meta {
			font-size: 0.75rem;
			line-height: 1.4;
			margin-top: 0.5rem;
		}
		a {
			color: inherit;
			text-decoration: none;
			color: var(--color-text);
		}
		@media only screen and (max-width: 900px) {
			max-width: 300px;
		}
	}
}
</style>
