<template>
<div class="relative group/menu">
	<div class="flex items-center cursor-pointer text-sm">
		Tools
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 ml-2">
			<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
		</svg>
	</div>
	<!-- style="transform: translateY(calc(-100% - 2.75rem));"  -->
	<div class="transform opacity-0 invisible scale-95 group-hover/menu:opacity-100 group-hover/menu:visible group-hover/menu:scale-100 delay-200 transition-all absolute right-0 top-7 z-10 w-72 p-4 rounded-xl bg-white shadow-2xl focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
		<div class="opacity-75 font-semibold text-sm py-2 pl-4">
			Color Tools
		</div>
		<router-link to="/tints-and-shades" class="group/button flex items-center justify-between hover:bg-neutral-100 p-2 pr-4 font-semibold rounded-xl">
			<div class="d-flex align-items-center">
				<div class="flex items-center justify-center w-10 h-10 items-center justify-center rounded-full border border-neutral-200 bg-white shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 mr-3">
					<svg class="w-5 fill-pink-500" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
						<path d="M34.35 18.45C34.05 17.85 33.6 17.55 33 17.55H4.8C4.95 17.1 5.25 16.8 5.55 16.35L16.8 5.1L26.85 15.15C27.45 15.75 28.35 15.75 28.95 15.15C29.55 14.55 29.55 13.65 28.95 13.05L18 1.95C17.4 1.35 16.5 1.35 15.9 1.95L3.45 14.25C2.25 15.6 1.5 17.25 1.5 19.05C1.5 19.05 1.5 19.05 1.5 19.2C1.5 19.35 1.5 19.2 1.5 19.35C1.5 21.15 2.25 22.8 3.45 24L12.15 32.7C13.5 34.05 15.15 34.65 16.95 34.65C18.75 34.65 20.4 34.05 21.75 32.7L34.05 20.25C34.5 19.65 34.65 19.05 34.35 18.45ZM19.5 30.45C18 31.95 15.6 31.95 14.25 30.45L5.55 21.75C5.25 21.45 4.95 21 4.8 20.55H29.4L19.5 30.45Z" />
					</svg>
				</div>
				<div>
					Tints & Shades
				</div>
			</div>
			<div>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="delay-100 transition-all opacity-0 -translate-x-3 group-hover/button:opacity-100 group-hover/button:-translate-x-0 w-4 h-4">
					<path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
				</svg>
			</div>
		</router-link>
		<router-link to="/gradient-palettes" class="group/button flex items-center justify-between hover:bg-neutral-100 p-2 pr-4 font-semibold rounded-xl">
			<div class="flex items-center">
				<div class="flex items-center justify-center w-10 h-10 drop-shadow-[0_16px_16px_rgba(0,0,0,0.085)] bg-white rounded-full mr-3">
					<svg class="w-5 fill-pink-500" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
						<path d="M32.55 14.3998C31.05 8.54976 26.25 4.04976 20.4 3.14976C16.05 2.39976 11.7 3.74976 8.4 6.59976C4.95 9.44976 3 13.7998 3 18.2998C3 24.8998 7.2 30.7498 13.35 32.8498C13.8 32.9998 14.25 33.1498 14.85 33.1498C15.75 33.1498 16.8 32.8498 17.55 32.2498C18.75 31.3498 19.5 29.8498 19.5 28.3498C19.5 26.2498 21.15 24.8998 23.4 24.8998H26.55C30.15 24.8998 33 22.0498 33 18.4498C33 16.9498 32.85 15.7498 32.55 14.3998ZM26.55 21.7498H23.4C19.5 21.7498 16.5 24.5998 16.5 28.1998C16.5 28.7998 16.2 29.2498 15.75 29.6998C15.45 29.8498 15 30.1498 14.25 29.9998C9.3 28.3498 6 23.5498 6 18.2998C6 14.6998 7.5 11.2498 10.35 8.84976C12.45 7.04976 15.15 5.99976 18 5.99976C18.6 5.99976 19.35 5.99976 19.95 6.14976C24.6 6.89976 28.5 10.4998 29.7 15.1498C30 16.1998 30.15 17.2498 30.15 18.2998C30 20.2498 28.5 21.7498 26.55 21.7498Z" />
						<path d="M12.7202 21.4174C13.8755 20.7676 14.2853 19.3043 13.6356 18.149C12.9858 16.9937 11.5225 16.5839 10.3672 17.2337C9.21194 17.8834 8.80212 19.3467 9.45187 20.502C10.1016 21.6573 11.5649 22.0671 12.7202 21.4174Z" />
						<path d="M16.0132 13.9183C17.1685 13.2686 17.5783 11.8053 16.9285 10.65C16.2788 9.49472 14.8155 9.0849 13.6602 9.73465C12.5049 10.3844 12.0951 11.8477 12.7448 13.003C13.3946 14.1583 14.8579 14.5681 16.0132 13.9183Z" />
						<path d="M24.105 14.9769C25.2602 14.3272 25.6701 12.8639 25.0203 11.7086C24.3706 10.5533 22.9073 10.1435 21.752 10.7932C20.5967 11.443 20.1869 12.9063 20.8366 14.0616C21.4864 15.2169 22.9497 15.6267 24.105 14.9769Z" />
					</svg>
				</div>
				<div>
					Gradient Palettes
				</div>
			</div>
			<div>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="delay-100 transition-all opacity-0 -translate-x-3 group-hover/button:opacity-100 group-hover/button:-translate-x-0 w-4 h-4">
					<path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
				</svg>
			</div>
		</router-link>
	</div>
</div>
</template>

<script>
export default {
	name: 'ToolsMenu'
}
</script>

<style lang="scss" scoped>
/* Main Dropdown */
// .tool-dropdown {
// 	left: -9999px;
// 	opacity: 0;
// 	padding: 20px 0;
// 	position: absolute;
// 	transition: opacity 0.3s ease-in-out;
// 	width: 320px;
// 	visibility: hidden;
// 	@media (max-width: 1200px) {
// 		width: 200px;
// 	}

// 	section {
// 		&:not(:last-of-type) {
// 			margin-bottom: 0.5rem;
// 		}
// 	}

// 	.text {
// 		margin-left: 1rem;
// 		font-size: 1rem;
// 		line-height: 1;
// 	}

// 	.h5 {
// 		font-size: 0.813rem;
// 		margin-bottom: 1rem;
// 	}

// 	.canvas {
// 		background-color: var(--color-background);
// 		border-radius: 1rem;
// 		box-shadow: 0 10px 70px rgb(0 0 0 / 15%);
// 	}

// 	.primary {
// 		padding: 1.5rem;
// 	}

// 	.secondary {
// 		padding: 1.5rem;
// 		background-color: #FAFAFA;
// 		border-bottom-left-radius: 1rem;
// 		border-bottom-right-radius: 1rem;
// 	}

// 	a {
// 		text-decoration: none;
// 		color: inherit;
// 		font-weight: 600;
// 	}

// 	.links {
// 		display: inline-grid;
// 		grid-template-columns: 1fr 1fr 1fr;
// 		grid-gap: 1rem;
// 	}

// 	.item {
// 		.arrow {
// 			transform: translateX(-0.5rem);
// 			transition: all 200ms ease-in;
// 			opacity: 0;
// 			svg {
// 				width: 0.75rem;
// 			}
// 		}
// 		&:hover {
// 			.arrow {
// 				opacity: 1;
// 				transform: translateX(0);
// 			}
// 		}
// 		&:not(:last-of-type) {
// 			margin-bottom: 1rem;
// 		}
// 		.tool-badge {
// 			width: 2.5rem;
// 			height: 2.5rem;
// 			border-radius: 5rem;
// 			background-color: white;
// 			box-shadow: 2px 2px 8px rgba(0,0,0,.08);
// 			.icon {
// 				width: 1.35rem;
// 				display: block;
// 				path {
// 					fill: var(--color-primary);
// 				}
// 			}
// 		}
// 	}

// 	&:before {
// 		background-color: var(--color-background);
// 		border-radius: 2px 0 0 0;
// 		content: '';
// 		height: 18px;
// 		left: calc(3rem - 9px);
// 		position: absolute;
// 		top: 15px;
// 		transform: rotate(45deg);
// 		width: 18px;
// 		@media only screen and (max-width: 800px) {
// 			right: calc(2rem - 9px);
// 			left: auto;
// 		}
// 	}
// }
// .dropdown-btn {
// 	font-size: 0.875rem;
// 	font-weight: 600;
// 	.material-icons-outlined {
// 		font-size: 1rem;
// 		margin-left: 0.5rem;
// 	}
// }
// .dropdown-btn:hover {
// 	color: var(--color-white);
// }
// .dropdown-btn:focus-within > div.tool-dropdown,
// .dropdown-btn:hover > div.tool-dropdown {
// 	left: 0;
// 	opacity: 1;
// 	transform: translateX(-2rem);
// 	visibility: visible;
// 	@media only screen and (max-width: 800px) {
// 		right: 0;
// 		left: auto;
// 		transform: translateX(0);
// 	}
// }
</style>
