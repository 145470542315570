import { createApp } from 'vue'

import router from '@/modules/router';

import App from './App.vue'

import VueGtag from "vue-gtag";
import VueNotify from '@aminoeditor/vue-notify';

createApp(App)
.use(VueNotify)
.use(VueGtag, {
	config: { id: "G-31RCLKG7S5" }
}, router)
.use(router)
.mount('#app')
