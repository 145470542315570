<template>
<main class="d-flex flex-column bg-white relative z-50 shadow-xl shadow-neutral-200/40">
	<NavbarPrimary class="z-50" />
	<div class="my-24 md:my-32 hero relative z-40 px-8">
		<div class="z-50 relative">
			<h1 class="h1 mx-auto font-black leading-none tracking-tight text-center text-pink-500">
				Seriously epic<br>
				browser color tools.
			</h1>
			<div class="text-center">
				<div class="text-xl my-6 mx-auto leading-relaxed max-w-xl">
					Powerful color tools including an eyedropper, color picker, and palette generator. Try it today—<span class="font-medium text-pink-500">It's free!</span>
				</div>
				<div class="flex justify-center items-center">
					<a href="https://chrome.google.com/webstore/detail/chroma/pkgejkfioihnchalojepdkefnpejomgn" target="_blank" class="flex w-20 h-20 flex items-center justify-center rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
						<img class="w-12 h-12" src="@/assets/images/browser-icons/chrome.svg">
					</a>
					<a href="https://microsoftedge.microsoft.com/addons/detail/chroma/kpibgeoppdepoloehjkdagmhmfejgkgb" target="_blank" class="mx-2 flex w-20 h-20 flex items-center justify-center rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
						<img class="w-12 h-12" src="@/assets/images/browser-icons/edge.svg">
					</a>
					<a href="https://chrome.google.com/webstore/detail/chroma/pkgejkfioihnchalojepdkefnpejomgn" target="_blank" class="flex w-20 h-20 flex items-center justify-center rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
						<img class="w-12 h-12" src="@/assets/images/browser-icons/brave.svg">
					</a>
				</div>
			</div>
		</div>
		<div class="max-w-7xl pt-32 px-16 rounded-3xl mx-auto mt-32 screenshot relative z-50">
			<img src="@/assets/images/home/screenshot.png">
		</div>
	</div>
	<div class="flex justify-center pb-24 md:pb-48 z-50 px-8">
		<div class="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24 max-w-3xl">
			<div class="prose prose-lg">
				<div class="flex items-center justify-center w-16 h-16 rounded-full bg-white shadow-2xl">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 stroke-pink-500">
						<path stroke-linecap="round" stroke-linejoin="round"
							d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
						<path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
					</svg>
				</div>
				<h2 class="mt-7 mb-5 leading-tight max-w-3/4">
					Sample colors from any page.
				</h2>
				<p class="opacity-75">
					Use the eyedropper to select and sample colors from any object or image, even outside the viewport.
				</p>
			</div>
			<div class="prose prose-lg">
				<div class="flex items-center justify-center w-16 h-16 rounded-full bg-white shadow-2xl">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 stroke-pink-500">
						<path stroke-linecap="round" stroke-linejoin="round" d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z" />
					</svg>
				</div>
				<h2 class="mt-7 mb-5 leading-tight max-w-3/4">
					One palette, many palettes.
				</h2>
				<p>
					Quickly generate palettes from any page. Generate shareable links to your palettes.
				</p>
			</div>
			<div class="prose prose-lg">
				<div class="flex items-center justify-center w-16 h-16 rounded-full bg-white shadow-2xl">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 stroke-pink-500">
						<path stroke-linecap="round" stroke-linejoin="round"
							d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
					</svg>
				</div>
				<h2 class="mt-7 mb-5 leading-tight max-w-3/4">
					Tools galore.
				</h2>
				<p>
					Generate gradients, tints, or shades based on any color.
				</p>
			</div>
			<div class="prose prose-lg">
				<div class="flex items-center justify-center w-16 h-16 rounded-full bg-white shadow-2xl">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 stroke-pink-500">
						<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
					</svg>
				</div>
				<h2 class="mt-7 mb-5 leading-tight max-w-3/4">
					Did we mention fast?
				</h2>
				<p>
					Enjoy Figma-esque eyedropper capabilities right in your browser. It's as fast as lightning.
				</p>
			</div>
		</div>
	</div>
</main>
<PrimaryFooter />
</template>

<script>
import {
	gsap
} from "gsap";
import NavbarPrimary from '@/components/Navbar';
import PrimaryFooter from '@/components/PrimaryFooter';
export default {
	name: 'IndexView',
	components: {
		NavbarPrimary,
		PrimaryFooter
	},
	async mounted() {
		gsap.to(".h1", {
			opacity: 1,
			visibility: 'visible',
			y: 0,
			delay: 0.25,
			duration: 0.25
		});
		gsap.to(".copy", {
			opacity: 1,
			visibility: 'visible',
			y: 0,
			delay: 0.5,
			duration: 0.25
		});
	}
}
</script>

<style lang="scss" scoped>

.h1 {
	font-size: clamp(4rem,5vw,6rem);
	font-weight: 900;
}

// main {
// 	&::after {
// 		content: "";
// 		position: absolute;
// 		top: 50%;
// 		left: 0;
// 		width: 100%;
// 		height: 100%;
// 		background-color: #21D4FD;
// 		background-image: linear-gradient(19deg, white 0%, #B721FF 70%);
// 		background-size: 100%;
// 		background-position: top;
// 		background-repeat: no-repeat;
// 		opacity: .1;
// 	}
// }

.screenshot {
	background-color: #21D4FD;
	background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);
}

</style>
