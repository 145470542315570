<template>
	<form class="relative" @submit.prevent="$router.push(`/${colorSearch}`)">
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="absolute top-1/2 -translate-y-1/2 left-4 w-5 h-5">
			<path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
		</svg>
		<input
			class="w-50 h-10 pl-11 h-11 font-semibold rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100" 
			maxlength="7"
			minlength="3"
			type="text"
			placeholder="Search by HEX"
			v-model="colorSearch"
			pattern="[A-Fa-f0-9]{3}|[A-Fa-f0-9]{6}"
			title="HEX value"
			@keyup="enforceHexInput"
		>
	</form>
</template>

<script>
	export default {
		name: 'SearchFormComponent',
		data () {
			return {
				colorSearch: ""
			}
		},
		methods: {
			enforceHexInput () {
				this.colorSearch = this.colorSearch.replaceAll(/[^a-fA-F0-9]+/g, '').toUpperCase().substring(0,6);
			}
		},
		mounted () {
			this.colorSearch = this.$route.params.color || this.colorSearch;
		},
		watch: {
			'$route.params.color': function () {
				this.colorSearch = this.$route.params.color;
			}
		}
	}
</script>
