<template>
<footer class="sticky bottom-0 bg-neutral-100/50 px-8">
	<div class="md:max-w-5xl m-auto py-20">
		<div class="max-w-5xl mx-auto mb-20">
			<ActionCards />
		</div>
		<div class="flex gap-12 justify-between">
			<div class="grid grid-cols-1 md:grid-cols-3 max-w-[700px] gap-12">
				<div class="leading-loose">
					<div class="opacity-75 uppercase font-medium tracking-wider text-sm mb-3">
						Extensions
					</div>
					<div class="font-semibold hover:underline">
						<a href="https://chrome.google.com/webstore/detail/chroma-eyedropper-color-p/pkgejkfioihnchalojepdkefnpejomgn" target="_blank">
							Chroma for Chrome
						</a>
					</div>
					<div class="font-semibold hover:underline">
						<a href="https://microsoftedge.microsoft.com/addons/detail/chroma-eyedropper-colo/kpibgeoppdepoloehjkdagmhmfejgkgb" target="_blank">
							Chroma for Edge
						</a>
					</div>
					<div class="font-semibold hover:underline">
						<a href="https://chrome.google.com/webstore/detail/chroma-eyedropper-color-p/pkgejkfioihnchalojepdkefnpejomgn" target="_blank">
							Chroma for Brave
						</a>
					</div>
				</div>
				<div class="leading-loose">
					<div class="opacity-75 uppercase font-medium tracking-wider text-sm mb-3">
						Tools
					</div>
					<div class="font-semibold hover:underline">
						<router-link to="/onboarding">
							Getting Started
						</router-link>
					</div>
					<div class="font-semibold hover:underline">
						<router-link to="/try">
							Try Chroma
						</router-link>
					</div>
					<div class="font-semibold hover:underline">
						<router-link to="/tints-and-shades">
							Tints and Shades
						</router-link>
					</div>
					<div class="font-semibold hover:underline">
						<router-link to="/gradient-palettes">
							Gradient Palettes
						</router-link>
					</div>
					<div class="font-semibold hover:underline">
						<a href="https://dev.us14.list-manage.com/subscribe/post?u=b17b9ff8c3dcec2f313a5c348&id=d207429c6e" target="_blank">
							Newsletter
						</a>
					</div>
				</div>
				<div class="leading-loose">
					<div class="opacity-75 uppercase font-medium tracking-wider text-sm mb-3">
						Legal
					</div>
					<div class="font-semibold hover:underline">
						<router-link to="/privacy">
							Privacy Policy
						</router-link>
					</div>
					<div class="font-semibold hover:underline">
						<router-link to="/terms">
							Terms of Use
						</router-link>
					</div>
				</div>
			</div>
			<div>
				<a href="https://x.com/ChromaDotDev" target="_blank">
					<div>
						<svg class="w-8 h-8" viewBox="0 0 24 24" aria-hidden="true"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>
					</div>
				</a>
			</div>
		</div>
	</div>
</footer>
</template>

<script>
import ActionCards from '@/components/ActionCards';
export default {
	name: 'PrimaryFooter',
	components: {
		ActionCards
	}
}
</script>

<style lang="scss" scoped>

</style>
