<template>
<div class="column relative flex flex-1 grow justify-center items-center p-5 text-white" :class="{ 'is-light': isLight }" :style="{ background: `#${color}` }">
	<div class="add hidden md:flex w-14 xl:w-20 items-center justify-center absolute top-0 left-0 bottom-0 -translate-x-1/2 group/add">
		<div v-if="allowAdd" @click="$emit('addColor')" class="tooltip flex justify-center items-center cursor-pointer w-8 h-8 text-sm rounded-full text-black bg-white shadow-sm transition-all duration-300 delay-100 opacity-0 invisible scale-90 group-hover/add:opacity-100 group-hover/add:visible group-hover/add:scale-100 hover:scale-105">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
				<path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
				</svg>
			<span class="tooltiptext">
				Add a color
			</span>
		</div>
	</div>
	<div class="flex h-full flex-col items-center" v-if="mode == 'color'">
		<div class="flex grow items-center">
			<div class="text-center">
				<div class="text-lg font-semibold tracking-tight">
					{{ colorName }}
				</div>
				<div class="tooltip relative mt-5">
					<span class="tooltiptext">
						Modify color
					</span>
					<div class="text-4xl xl:text-5xl font-black tracking-tight leading-none uppercase cursor-pointer relative">
						<slot />{{ color }}
					</div>
				</div>
				<div class="opacity-75 hidden md:block mt-5">
					{{ hexToRGB(color) }}
				</div>
			</div>
		</div>
		<div class="hidden md:flex actions">
			<div :style="{'--column-color': `#${color}`}" @click.prevent="copyColor" class="mr-1 relative tooltip flex justify-center items-center cursor-pointer w-8 h-8 text-sm rounded-full text-white bg-white/10 shadow-sm hover:bg-white/20 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.75" stroke="currentColor" class="w-4 h-4">
					<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
				</svg>
				<span class="tooltiptext">
					Copy HEX value
				</span>
			</div>
			<div :style="{'--column-color': `#${color}`}" v-if="movable" @click.prevent="$router.push(`/${color}`)" class="mr-1 relative tooltip flex justify-center items-center cursor-pointer w-8 h-8 text-sm rounded-full text-white bg-white/10 shadow-sm hover:bg-white/20 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.75" stroke="currentColor" class="w-4 h-4">
					<path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
				</svg>
				<span class="tooltiptext">
					Color details
				</span>
			</div>
			<div :style="{'--column-color': `#${color}`}" v-if="movable" class="mr-1 drag-handle relative tooltip flex justify-center items-center cursor-pointer w-8 h-8 text-sm rounded-full text-white bg-white/10 shadow-sm hover:bg-white/20 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.75" stroke="currentColor" class="w-4 h-4">
					<path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
				</svg>
				<span class="tooltiptext">
					Drag color
				</span>
			</div>
			<!-- <div class="item d-flex align-items-center justify-content-center cursor-pointer" @click.prevent="mode = 'shades'">
				<span class="material-icons-outlined">
					table_rows
				</span>
			</div> -->
			<div :style="{'--column-color': `#${color}`}" @click.prevent="$emit('remove')" v-if="removable" class="relative relative tooltip flex justify-center items-center cursor-pointer w-8 h-8 text-sm rounded-full text-white bg-white/10 shadow-sm hover:bg-white/20 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.75" stroke="currentColor" class="w-4 h-4">
					<path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
				</svg>
				<span class="tooltiptext">
					Remove color
				</span>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { ColorHelpers } from '@/util/ColorUtil';
export default {
	name: 'ColorColumn',
	props: {
		color: String,
		movable: Boolean,
		removable: Boolean,
		allowAdd: Boolean
	},
	data() {
		return {
			mode: 'color'
		}
	},
	methods: {
		async copyColor() {
			await navigator.clipboard.writeText(`${this.color}`);
			this.$notify(`${this.color} copied to clipboard`, {
				displayMs: 2500
			});
		},
		pickColor(e) {
			e.target.getElementsByClassName('color-picker')[0].focus()
		},
		hexToRGB(hex) {
			const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
			return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
		}
	},
	computed: {
		colorName () {
			return ColorHelpers.getColorName(this.color);
		},
		isLight() {
			return ColorHelpers.isLight(this.color.replace('#', ''));
		},
	}
}
</script>

<style scoped lang="scss">
	.column {
		&:first-of-type {
			.add {
				@apply translate-x-0;
			}
		}
		&.is-light {
			@apply text-black;
			.actions {
				.tooltip {
					@apply text-black;
					@apply bg-black/5;
					&:hover {
						@apply bg-black/10;
					}
				}
			}
		}
		
	}
</style>
