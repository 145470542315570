<template>
<div class="body flex flex-col h-full">
	<div class="absolute top-3 left-5 flex items-center gap-6 z-50">
		<div>
			<IconMain />
		</div>
		<a href="javascript:void(0);" class="flex items-center text-sm h-11 font-semibold py-2 px-4 bg-white border border-neutral-300 rounded-full hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100" @click.prevent="generateRandomPalette">
			Create a palette
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 ml-2">
				<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
			</svg>
		</a>
	</div>
	
	<div class="flex flex-col grow justify-center items-center text-center content py-36 px-12">
		<div class="tooltip-chrome">
			<div class="tooltip-chrome-content">
				<span>Click the</span> <img class="inline-flex" src="@/assets/images/extension-icon.svg"> <span>icon and make sure to pin the Chroma</span> <img class="inline-flex" src="@/assets/images/icon.svg" width="16"> <span>icon.</span>
			</div>
		</div>
		<img class="tooltip-chrome-icon" src="@/assets/images/tooltip-icon.svg">
		<header>
			<div class="h1 font-black leading-none tracking-tight text-center text-pink-500 mb-4">
				Get started right.
			</div>
			<div class="opacity-75 text-xl">
				How to get started picking colors and building palettes with Chroma.
			</div>
		</header>
		<div class="cards">
			<div class="card rounded-xl shadow-xl" @mouseover="mouseOver" @mouseleave="mouseLeave">
				<div class="card-wrap font-semibold text-sm">
					<figure class="bg-neutral-100 rounded-xl">
						<img src="@/assets/images/onboarding/1.svg">
					</figure>
					Open Chroma in the top right of the toolbar
				</div>
			</div>
			<div class="card" @mouseover="mouseOver" @mouseleave="mouseLeave">
				<div class="card-wrap font-semibold text-sm">
					<figure class="bg-neutral-100 rounded-xl">
						<img src="@/assets/images/onboarding/2.svg">
					</figure>
					Or, open Chroma with a right click
				</div>
			</div>
			<div class="card" @mouseover="mouseOver" @mouseleave="mouseLeave">
				<div class="card-wrap font-semibold text-sm">
					<figure class="bg-neutral-100 rounded-xl">
						<img src="@/assets/images/onboarding/3.png">
					</figure>
					Use the eyedropper to grab a color, or create a palette
				</div>
			</div>
			<a href="https://chromewebstore.google.com/detail/chroma-ultimate-eyedroppe/pkgejkfioihnchalojepdkefnpejomgn/reviews" target="_blank" class="card opt-in group" @mouseover="mouseOver" @mouseleave="mouseLeave">
				<div class="card-wrap flex flex-col h-full justify-between">
					<div class="card-header flex flex-col grow justify-center items-center p-0">
						<div class="flex items-center justify-center">
							<svg class="w-8 h-8 shrink-0" fill="#EC4899" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
								<path clip-rule="evenodd" fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"></path>
							</svg>
							<svg class="w-8 h-8 shrink-0" fill="#EC4899" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
								<path clip-rule="evenodd" fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"></path>
							</svg>
							<svg class="w-8 h-8 shrink-0" fill="#EC4899" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
								<path clip-rule="evenodd" fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"></path>
							</svg>
							<svg class="w-8 h-8 shrink-0" fill="#EC4899" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
								<path clip-rule="evenodd" fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"></path>
							</svg>
							<svg class="w-8 h-8 shrink-0" fill="#EC4899" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
								<path clip-rule="evenodd" fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"></path>
							</svg>
						</div>
						<div class="font-bold text-xl leading-tight mt-4 mb-2">
							Give us a review!
						</div>
						<div class="font-semibold text-sm opacity-70">
							Support us by letting the community know you're enjoying Chroma.
						</div>
					</div>
					<div class="mt-6">
						<div class="flex gap-2 justify-center items-center text-sm h-11 font-semibold py-2 px-4 bg-white border border-neutral-300 rounded-full transition delay-200 group-hover:bg-[#EC4899] group-hover:text-white transiton group-hover:border-[#EC4899] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
							Leave a review
							<svg class="w-4 h-4 shrink-0 stroke-2" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
								<path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"></path>
							</svg>
						</div>
					</div>
					<!-- <form class="mt-5" action="https://dev.us14.list-manage.com/subscribe/post?u=b17b9ff8c3dcec2f313a5c348&amp;id=d207429c6e" method="post" target="_blank" novalidate>
						<div class="flex items-center">
							<div class="grow mr-2">
								<input class="h-10 pl-4 w-full bg-neutral-100 rounded-full required email text-sm" type="email" value="" name="EMAIL" placeholder="Email address" id="mce-EMAIL">
							</div>
							<div>
								<button class="flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-md" type="submit">
									<svg class="w-5 h-5 fill-pink-500" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M34.2338 7.51078C35.5433 3.76996 31.9778 0.682557 28.5236 1.70178C28.503 1.70786 28.4825 1.71439 28.4622 1.72135L4.55326 9.90839C0.392277 11.2435 0.514338 17.1038 4.73516 18.3742C4.74944 18.3784 4.76378 18.3825 4.77819 18.3864L12.8006 20.5422C12.8175 20.5468 12.8345 20.551 12.8515 20.555C14.0766 20.8386 15.0179 21.7858 15.4728 23.2455L17.6125 31.2594C17.6128 31.2607 17.6131 31.2619 17.6134 31.2631C18.7339 35.5219 24.5899 35.5388 26.0585 31.5397L26.0705 31.5057L34.2338 7.51078ZM29.3997 4.57134C30.7997 4.17741 31.8003 5.3883 31.4011 6.52317L23.237 30.52C22.6933 31.9649 20.8278 31.6933 20.5143 30.4983L18.3638 22.4435C18.359 22.4258 18.354 22.4081 18.3486 22.3906C17.6571 20.1358 16.0328 18.2234 13.5555 17.6387L5.58137 15.4958C4.09734 15.0335 4.2179 13.1616 5.47724 12.7626L5.51015 12.7517L29.3997 4.57134Z" />
									</svg>
								</button>
							</div>
						</div>
						<div id="mce-responses" class="clear">
							<div class="response" id="mce-error-response" style="display:none"></div>
							<div class="response" id="mce-success-response" style="display:none"></div>
						</div>
						<div style="position: absolute; left: -5000px;" aria-hidden="true">
							<input type="text" name="b_85f2f6a5880f51d5dda6e1b16_592bdbf171" tabindex="-1" value="">
						</div>
					</form> -->
				</div>
			</a>
			<!-- <div class="card" @mouseover="mouseOver" @mouseleave="mouseLeave">
				<div class="card-wrap">
					<figure class="d-flex align-items-center justify-content-center">
						<img src="@/assets/images/onboarding/4.svg">
					</figure>
					Open your palette and share it with others!
				</div>
			</div> -->
		</div>
		<div class="bg-white rounded-lg p-2 m-4 hidden md:block absolute bottom-0 left-0 shadow-xl max-w-[400px]">
			<Ad type="carbon" :cid="['CESDC27Y', 'CESDC277']" v-slot="{ ad, renderLink, renderImage }" show :key="color">
				<a class="block" :href="renderLink(ad.statlink)" target="_self" v-if="ad.rendering == 'custom'">
					<div class="flex text-left items-center">
						<div class="rounded overflow-hidden mr-4 p-2" :style="{ 'background-color': ad.backgroundColor}">
							<img :src="renderImage(ad.logo)" class="w-52 block" />
						</div>
						<div class="w-full flex flex-col">
							<div class="text-xs">
								{{ ad.description }}
							</div>
							<div class="text-xs opacity-50 mt-1">
								Sponsored by Carbon
							</div>
						</div>
					</div>
				</a>
				<a class="block" :href="renderLink(ad.statlink)" target="_self" v-if="ad.rendering == 'carbon'">
					<div class="flex text-left items-center">
						<div class="rounded overflow-hidden mr-4">
							<img :src="renderImage(ad.smallImage)" class="w-28 block rounded-sm" />
						</div>
						<div class="w-full flex flex-col">
							<div class="text-xs">
								{{ ad.description }}
							</div>
							<div class="text-xs opacity-50 mt-1">
								Sponsored by Carbon
							</div>
						</div>
					</div>
				</a>
			</Ad>
		</div>
	</div>
</div>
</template>

<script>
import IconMain from '@/components/Icon';
import Ad from '@/components/Ad';
import { ColorHelpers } from '@/util/ColorUtil';
export default {
	name: 'OnboardingView',
	components: {
		Ad,
		IconMain
	},
	methods: {
		generateRandomPalette () {
			const palette = ColorHelpers.getRandomPalette();
			const key = palette.map(c => c.replace('#', '').toUpperCase()).join('-');
			this.$router.push(`/${key}`);
		}
	}
}
</script>

<style lang="scss" scoped>
.body {
	&:after {
		content: "";
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		// background-image: radial-gradient(at 70% 35%, rgba(248, 186, 252, 0.75) 0, rgba(248, 186, 252, 0) 18%), radial-gradient(at 40% 75%, rgba(224, 87, 160, 0.5) 0, rgba(224, 87, 160, 0) 28%), radial-gradient(at 34% 25%, rgba(248, 186, 252, 0.75) 0, rgba(248, 186, 252, 0) 22%), radial-gradient(at 60% 64%, rgba(248, 186, 252, 0.75) 0, rgba(248, 186, 252, 0) 36%), radial-gradient(at 46% 53%, rgba(168, 86, 245, 0.75) 0, rgba(248, 186, 252, 0) 53%);
		background: radial-gradient(#A259FF 0%, #FDF6F8 80%);
		background-size: 100%;
		background-position: center;
		background-repeat: no-repeat;
		opacity: 0.1;
	}
}
.content {
	z-index: 10;
}
header {
	margin-bottom: 3rem;
}
.h1 {
	font-size: clamp(4rem,5vw,8rem);
}
.description {
	font-size: 1.1em;
}
.tooltip-chrome {
	position: absolute;
	top: 60px;
	right: 40px;
	width: 300px;
	img {
		vertical-align: middle;
		margin-top: -4px;
	}
	@media (max-width: 700px) {
		display: none;
	}
}
.tooltip-chrome-icon {
	position: absolute;
	top: 14px;
	right: 110px;
	@media (max-width: 700px) {
		display: none;
	}
}
.opt-in {
	.card-header {
		padding: 0 1rem;
		.icon {
			margin: 0 auto 0.5rem;
			width: 2.5rem;
			path {
				stroke: var(--color-primary);
			}
		}
	}
	
	.title {
		font-size: 1.5rem;
		font-weight: bold;
		line-height: 1.2;
		margin-bottom: 0.5rem;
	}
	.subtitle {
		opacity: 0.5;
		font-size: 0.8rem;
	}
}
.cards {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 3rem;
	max-width: 1300px;
	@media (max-width: 1200px) {
		grid-template-columns: 1fr 1fr;
	}
	@media (max-width: 700px) {
		grid-template-columns: 1fr;
		grid-gap: 3rem;
		.card {
			max-width: 100%;
		}
	}
}

.card {
	padding: 1.5rem;
	position: relative;
	border-radius: 0.5rem;
	width: 100%;
	/* max-width: 300px; */
	font-size: 1.1rem;
	box-shadow: 0 1rem 3rem rgba(0,0,0,0.08);
	.card-wrap {
		position: relative;
	}

	figure {
		
		margin-bottom: 1.5rem;
		border-radius: 0.5rem;
		img {
			max-width: 100%;
			z-index: 10;
			display: block;
			border-radius: 1rem;
		}

	}

	&:hover {
		&:before {
			box-shadow: 0 1rem 3rem rgba(0,0,0,0.16);
			transform: scale(1.04);
		}
	}

	&:before {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: var(--color-background);
		border-radius: 0.5rem;
		transition: all 0.6s cubic-bezier(0.52, 0.01, 0, 1.01);
	}
}

.btn {
	background-color: #FDF8F1;
	border: 0;
	box-shadow: 0 4px 15px rgba(0,0,0,0.10);
	font-size: 1.1em;
	margin-top: 1.5rem;
	.material-icons-outlined {
		font-size: 1.5em;
		margin-right: 0.5rem;
		color: #FF7051;
	}
}

</style>
