<template>
<router-link to="/" class="logo flex items-center">
	<!-- <svg class="block icon w-7 h-auto" width="201" height="201" viewBox="0 0 201 201" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.000719337 0.000717553V99.7519H100C100 73.2957 89.4643 47.924 70.7102 29.2171C51.9575 10.5095 26.5212 0 0 0L0.000719337 0.000717553Z" fill="#FE5489" />
		<path d="M100.001 0.000717553V99.7519H200C200 73.2957 189.464 47.924 170.71 29.2171C151.957 10.5095 126.521 0 100 0L100.001 0.000717553Z" fill="#FE5489" />
		<path d="M100.499 150.001C100.499 122.249 78.0017 99.7518 50.2496 99.7518C22.4975 99.7518 0 122.249 0 150.001C0 177.754 22.4975 200.251 50.2496 200.251C78.0017 200.251 100.499 177.754 100.499 150.001Z" fill="#FE5489" />
		<path d="M100.501 99.7527V199.504H200.5C200.5 173.048 189.964 147.676 171.21 128.969C152.457 110.261 127.021 99.752 100.5 99.752L100.501 99.7527Z" fill="#FE5489" />
	</svg> -->
	<svg class="block icon w-28 h-auto" width="194" height="40" viewBox="0 0 194 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path class="icon-path" d="M0.000143509 0.000143331V19.9254H19.9501C19.9501 14.6408 17.8482 9.57279 14.1068 5.8361C10.3656 2.09927 5.29102 0 0 0L0.000143509 0.000143331Z" fill="#FE5489"/>
		<path class="icon-path" d="M19.9503 0.000143331L19.9501 19.9254H39.9002C39.9002 14.6408 37.7984 9.57279 34.0569 5.8361C30.3157 2.09927 25.2411 0 19.9501 0L19.9503 0.000143331Z" fill="#FE5489"/>
		<path class="icon-path" d="M20.0497 29.9627C20.0497 24.4192 15.5614 19.9253 10.0249 19.9253C4.48827 19.9253 0 24.4192 0 29.9627C0 35.5061 4.48827 40 10.0249 40C15.5614 40 20.0497 35.5061 20.0497 29.9627Z" fill="#FE5489"/>
		<path class="icon-path" d="M20.05 19.9255V39.8507H40C40 34.5662 37.8981 29.4982 34.1566 25.7615C30.4155 22.0247 25.3409 19.9254 20.0499 19.9254L20.05 19.9255Z" fill="#FE5489"/>
		<path d="M74.9659 23.232C74.1932 27.0954 71.4185 28.2193 68.7844 28.2193C65.1317 28.2193 62.3571 25.3393 62.3571 19.9657C62.3571 14.7676 65.1668 11.7823 68.7141 11.7823C71.559 11.7823 74.1229 13.2925 74.7902 16.7696H80.6907C79.8478 10.9745 75.282 6.83008 68.9249 6.83008C60.9522 6.83008 56 11.9228 56 19.9657C56 28.114 60.8117 33.1715 68.7493 33.1715C75.1766 33.1715 79.918 29.1325 80.8663 23.232H74.9659Z" fill="black"/>
		<path d="M89.4361 7.25154H83.641V32.715H89.4361V23.0213C89.4361 19.5091 90.4898 17.8935 92.9834 17.8935C95.7932 17.8935 95.8634 20.1062 95.8634 22.8106V32.715H101.659V21.3706C101.659 16.2428 100.148 13.3979 95.3015 13.3979C92.3161 13.3979 90.279 14.9081 89.4361 16.313V7.25154Z" fill="black"/>
		<path d="M117.08 13.714C116.94 13.6789 116.799 13.6789 116.624 13.6789H116.202C113.498 13.7842 111.917 15.154 111.039 17.4018V13.8193H105.455V32.715H111.25V23.232C111.25 20.2818 112.69 19.2633 115.5 19.2633C116.027 19.2633 116.413 19.2633 117.08 19.3686V13.714Z" fill="black"/>
		<path d="M128.438 17.753C130.721 17.753 132.547 19.1579 132.547 23.1969C132.547 27.1657 130.791 28.6759 128.438 28.6759C126.084 28.6759 124.328 27.1657 124.328 23.1969C124.328 19.1579 126.155 17.753 128.438 17.753ZM128.438 13.2574C123.204 13.2574 118.428 16.3481 118.428 23.1969C118.428 30.0457 123.204 33.1715 128.438 33.1715C133.671 33.1715 138.447 30.0457 138.447 23.1969C138.447 16.3481 133.671 13.2574 128.438 13.2574Z" fill="black"/>
		<path d="M147.264 22.8808C147.264 19.5091 148.247 17.8935 150.636 17.8935C153.41 17.8935 153.551 20.1062 153.551 22.8106V32.715H159.346V22.8808C159.346 19.5091 160.329 17.8935 162.718 17.8935C165.492 17.8935 165.633 20.1062 165.633 22.8106V32.715H171.428V21.3706C171.428 16.2428 169.918 13.3979 165.071 13.3979C162.401 13.3979 160.048 14.4867 158.608 16.594C157.73 14.4867 156.044 13.3979 152.989 13.3979C150.144 13.3979 148.107 14.9081 147.264 16.313V13.8193H141.469V32.715H147.264V22.8808Z" fill="black"/>
		<path d="M188.144 16.3832C186.914 14.2057 184.421 13.3979 182.313 13.3979C177.431 13.3979 173.989 17.0857 173.989 23.2672C173.989 29.4486 177.431 33.1715 182.313 33.1715C184.421 33.1715 186.914 32.3637 188.144 30.1862V32.715H193.904V13.8193H188.144V16.3832ZM184.21 17.8935C186.493 17.8935 188.389 19.3686 188.389 23.2672C188.389 27.1657 186.493 28.6759 184.21 28.6759C181.892 28.6759 179.995 26.7793 179.995 23.2672C179.995 19.755 181.892 17.8935 184.21 17.8935Z" fill="black"/>
	</svg>
	<!-- <div class="font-semibold ml-2">
		Chroma
	</div> -->
</router-link>
</template>

<script>
export default {
	name: 'IconMain'
}
</script>

<style lang="scss" scoped>
.yuge {
	.icon {
		.icon-path {
			@apply w-32;
			@apply h-32;
		}
	}
}
.logo {
	&:hover {
		.icon {
			.icon-path {
				animation-name: onHoverAnimation;
				animation-duration: 500ms;
				animation-iteration-count: infinite;
			}
		}
	}
}
.icon {
	transition: 0.3s all cubic-bezier(.17,.67,.83,.67);
}
@keyframes onHoverAnimation {
	0% {
		fill: var(--color-primary);
	}
	25% {
		fill: #A259FF;
	}
	50% {
		fill: #F24E1E;
	}
	75% {
		fill: #1ABCFE;
	}
	100% {
		fill: #0ACF83;
	}
}
</style>
