<template>
<div class="grid grid-cols-1 md:grid-cols-2 gap-8">
	<div class="w-full border border-neutral-100 shadow-lg shadow-neutral-200/40 hover:shadow-xl hover:shadow-neutral-200/80 flex p-12 flex-col items-center justify-center text-center rounded-2xl bg-white transition-all delay-100 prose">
		<h2 class="m-0 leading-snug">
			Get the Chroma browser extension
		</h2>
		<div class="opacity-75 mt-3 mb-5 leading-snug">
			Interact with colors as you browse the web.
		</div>
		<div class="flex items-center">
			<a href="https://chrome.google.com/webstore/detail/chroma/pkgejkfioihnchalojepdkefnpejomgn" target="_blank" class="flex w-12 h-12 flex items-center justify-center rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
				<img class="w-8 h-8" src="@/assets/images/browser-icons/chrome.svg">
			</a>
			<a href="https://microsoftedge.microsoft.com/addons/detail/chroma/kpibgeoppdepoloehjkdagmhmfejgkgb" target="_blank" class="mx-2 flex w-12 h-12 flex items-center justify-center rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
				<img class="w-8 h-8" src="@/assets/images/browser-icons/edge.svg">
			</a>
			<a href="https://chrome.google.com/webstore/detail/chroma/pkgejkfioihnchalojepdkefnpejomgn" target="_blank" class="flex w-12 h-12 flex items-center justify-center rounded-full border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
				<img class="w-8 h-8" src="@/assets/images/browser-icons/brave.svg">
			</a>
		</div>
	</div>
	<div class="w-full border border-neutral-100 shadow-lg shadow-neutral-200/40 hover:shadow-xl hover:shadow-neutral-200/80 flex p-12 flex-col items-center justify-center text-center rounded-2xl bg-white transition-all delay-100 prose">
		<h2 class="m-0 leading-snug">
			Get the Chroma newsletter
		</h2>
		<div class="opacity-75 mt-3 mb-5 leading-snug">
			Hear about new features and releases. Don't worry, we hate spam too.
		</div>
		<div class="w-full max-w-xs">
			<form action="https://dev.us14.list-manage.com/subscribe/post?u=b17b9ff8c3dcec2f313a5c348&amp;id=d207429c6e" method="post" target="_blank" novalidate>
				<div class="flex items-center">
					<div class="grow mr-2">
						<input class="h-10 pl-4 w-full bg-neutral-100 rounded-full required email" type="email" value="" name="EMAIL" placeholder="Email address" id="mce-EMAIL">
					</div>
					<div>
						<button class="flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-md" type="submit">
							<svg class="w-5 h-5 fill-pink-500" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M34.2338 7.51078C35.5433 3.76996 31.9778 0.682557 28.5236 1.70178C28.503 1.70786 28.4825 1.71439 28.4622 1.72135L4.55326 9.90839C0.392277 11.2435 0.514338 17.1038 4.73516 18.3742C4.74944 18.3784 4.76378 18.3825 4.77819 18.3864L12.8006 20.5422C12.8175 20.5468 12.8345 20.551 12.8515 20.555C14.0766 20.8386 15.0179 21.7858 15.4728 23.2455L17.6125 31.2594C17.6128 31.2607 17.6131 31.2619 17.6134 31.2631C18.7339 35.5219 24.5899 35.5388 26.0585 31.5397L26.0705 31.5057L34.2338 7.51078ZM29.3997 4.57134C30.7997 4.17741 31.8003 5.3883 31.4011 6.52317L23.237 30.52C22.6933 31.9649 20.8278 31.6933 20.5143 30.4983L18.3638 22.4435C18.359 22.4258 18.354 22.4081 18.3486 22.3906C17.6571 20.1358 16.0328 18.2234 13.5555 17.6387L5.58137 15.4958C4.09734 15.0335 4.2179 13.1616 5.47724 12.7626L5.51015 12.7517L29.3997 4.57134Z" />
							</svg>
						</button>
					</div>
				</div>
				<div id="mce-responses" class="clear">
					<div class="response" id="mce-error-response" style="display:none"></div>
					<div class="response" id="mce-success-response" style="display:none"></div>
				</div>
				<div style="position: absolute; left: -5000px;" aria-hidden="true">
					<input type="text" name="b_85f2f6a5880f51d5dda6e1b16_592bdbf171" tabindex="-1" value="">
				</div>
			</form>
		</div>
	</div>
</div>
</template>

<script>
export default {
	name: 'ActionCards'
}
</script>

<style lang="scss" scoped>
</style>
